<template>
<!--     Termly Tracking Code-->
    <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-white">
        <div>
            <div name="termly-embed" data-id="598bc2a2-e09c-4113-9f78-444a6684b812" data-type="iframe" class="bg-white my-10 rounded-md"></div>
        </div>

        <div name="script">
            <a href="https://www.iubenda.com/privacy-policy/64016478" class="iubenda-nostyle no-brand iubenda-embed iub-body-embed" title="Privacy Policy">Privacy Policy</a>
        </div>
    </div>


<!--    <div>-->
<!--        <a href="https://www.iubenda.com/privacy-policy/64016478" class="iubenda-nostyle no-brand iubenda-embed iub-body-embed" title="Privacy Policy">Privacy Policy</a><script type="text/javascript">(function (w,d) {var loader = function () {var s = d.createElement("script"), tag = d.getElementsByTagName("script")[0]; s.src="https://cdn.iubenda.com/iubenda.js"; tag.parentNode.insertBefore(s,tag);}; if(w.addEventListener){w.addEventListener("load", loader, false);}else if(w.attachEvent){w.attachEvent("onload", loader);}else{w.onload = loader;}})(window, document);</script>-->

<!--    </div>-->
</template>



<script>

    // let privacy = (function(d, s, id) {
    //     var js, tjs = d.getElementsByTagName(s)[0];
    //     if (d.getElementById(id)) return;
    //     js = d.createElement(s); js.id = id;
    //     js.src = "https://app.termly.io/embed-policy.min.js";
    //     tjs.parentNode.insertBefore(js, tjs);
    // }(document, 'script', 'termly-jssdk'));
    // const axios = require('../axios-auth')
    export default {

        name: "Privacy",
        created() {
            this.getIubendaPrivate(window, document)
            this.getPrivacy(document, 'script', 'termly-jssdk')

        },
        methods: {
            getIubendaPrivate(w,d) {
                var loader = function () {
                    var s = d.createElement("script"), tag = d.getElementsByTagName("script")[0];
                    s.src="https://cdn.iubenda.com/iubenda.js";
                    tag.parentNode.insertBefore(s,tag);
                };
                if(w.addEventListener){
                    w.addEventListener("load", loader, false);
                } else if(w.attachEvent){
                    w.attachEvent("onload", loader);
                }else{
                    w.onload = loader;
                }
            },
            getPrivacy(d, s, id) {
                // console.log("Trying to get privacy", d, s, id)
                // console.log("the document is:" , d)
                let js, tjs = d.getElementsByTagName(s)[0];
                if (d.getElementById(id)) return;
                js = d.createElement(s); js.id = id;
                js.src = "https://app.termly.io/embed-policy.min.js";
                tjs.parentNode.insertBefore(js, tjs);

                setTimeout(function(){
                    var event = document.createEvent("Event");
                    event.initEvent("resize", true, true);
                    window.dispatchEvent(event);
                }, 3000)
            }
        }
    }
</script>

<style scoped>

</style>
